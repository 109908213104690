import "./sass/main.scss";
import "foundation-sites";
import "slick-carousel";
import "jquery-countdown";
import "./js/custom";
import "./js/preview";
import "./js/testimonial-video";
import "./js/customizer";
import "./js/skip-link-focus-fix";
import runAfterDomLoad from "./js/v4";
import { Foundation } from "foundation-sites/js/foundation.core";
import { Reveal } from "foundation-sites/js/foundation.reveal";

Foundation.addToJquery(jQuery);
Foundation.plugin(Reveal, "Reveal");
jQuery(document).foundation();

document.addEventListener("DOMContentLoaded", runAfterDomLoad);
