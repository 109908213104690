import $ from "jquery";

export function initAccordion() {
    if (document.querySelector(".v4-faq__item")) {
        $(".v4-faq__item").each(function() {
            const p = $(this);
            $(".v4-faq__head", this).on("click", function(e) {
                $(this).toggleClass("v4-faq__head--open");
                $(this)
                    .next()
                    .slideToggle();
                $(".v4-faq__head")
                    .not(this)
                    .removeClass("v4-faq__head--open");
                $(".v4-faq__head")
                    .not(this)
                    .next()
                    .slideUp();
            });
        });
    }
}
