import Player from "@vimeo/player";
import { Reveal } from "foundation-sites/js/foundation.reveal";

const $ = jQuery;

$(() => {
    const $preview = $(".preview");

    if ($preview.length < 1) {
        return;
    }

    const $video = $preview.find(".preview__video");
    const player = new Player($video.get(0));

    $preview.click(function(e) {
        if ($(e.target).closest(".preview__cta").length > 0) {
            return;
        }

        if (!$preview.hasClass("preview--playback")) {
            player.play();
        } else {
            player.pause();
        }
    });

    const thresholds = {
        0.25: false,
        0.5: false,
        0.75: false,
        1: false
    };

    player.on("progress", function(e) {
        const { percent } = e;

        Object.keys(thresholds).forEach(threshold => {
            if (percent >= parseFloat(threshold) && !thresholds[threshold]) {
                window.dataLayer.push({
                    event: "video-progress",
                    progress: threshold
                });
                thresholds[threshold] = true;
            }
        });
    });

    player.on("play", function() {
        window.dataLayer.push({
            event: "video-played"
        });
        $preview.addClass("preview--playback");
    });

    player.on("pause", function() {
        player.pause();
        $preview.removeClass("preview--playback");
        window.dataLayer.push({
            event: "video-stopped"
        });
    });
});
