import Player from "@vimeo/player";

const $ = jQuery;

$(() => {
    const $preview = $(".testimonial-video__video");

    if ($preview.length < 1) {
        return;
    }

    const $video = $preview.find("iframe");
    console.log($video);
    const player = new Player($video.get(0));

    $preview.click(function(e) {
        if (!$preview.hasClass("testimonial-video__video--playback")) {
            player.play();
        } else {
            player.pause();
        }
    });

    const thresholds = {
        0.25: false,
        0.5: false,
        0.75: false,
        1: false
    };

    player.on("progress", function(e) {
        const { percent } = e;

        Object.keys(thresholds).forEach(threshold => {
            if (percent >= parseFloat(threshold) && !thresholds[threshold]) {
                window.dataLayer.push({
                    event: "video-progress",
                    progress: threshold
                });
                thresholds[threshold] = true;
            }
        });
    });

    player.on("play", function() {
        window.dataLayer.push({
            event: "video-played"
        });
        $preview.addClass("testimonial-video__video--playback");
    });

    player.on("pause", function() {
        player.pause();
        $preview.removeClass("testimonial-video__video--playback");
        window.dataLayer.push({
            event: "video-stopped"
        });
    });
});
