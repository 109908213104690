import $ from "jquery";

export function initGallerySlick() {
    $(".v4-gallery-slick__slick").slick({
        arrows: true,
        slidesToShow: 3,
        dots: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
}
