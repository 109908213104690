export function video() {
    let timeOut;

    timeOut = window.setTimeout(showVideo, 2000);

    function showVideo() {
        document.querySelectorAll("video").forEach(video => {
            if (
                video.parentElement.classList.contains("v4-v4-hero__video") &&
                document.body.clientWidth < 575
            ) {
                return;
            }

            video.querySelectorAll("source").forEach(videoSource => {
                if (videoSource.dataset.src) {
                    videoSource.src = videoSource.dataset.src;
                }
            });
            video.load();
            if (video.autoplay) {
                video.play();
            }
        });
    }
}
