import ModalWindow from "./components/ModalWindow/ModalWindow";
import ModalVideoWindow from "./components/ModalWindow/ModalVideoWindow";
import { initGallerySlick } from "./components/slick";
import { initAccordion } from "./components/faq";
import { video } from "./components/video";

export default function runAfterDomLoad() {
    initGallerySlick();
    initAccordion();
    video();
    new ModalWindow(ModalVideoWindow);
}
