import Glide from "@glidejs/glide";
import { once } from "lodash";

import $ from "jquery";

const DISABLED_CLASS = "ias-lesson-mark-complete--disabled";

(function($) {
    $(function() {
        //$(document).foundation();
        /*$(".mobile-menu").click(function() {
            $("#primary-navigation").slideToggle();
            $(this).toggleClass("active");
        });*/

        $(".auth__flash").click(function() {
            $(this).addClass("auth__flash--hidden");
        });

        const $countdown = $(".launch-countdown");
        const date = $countdown.attr("data-launch-date");
        $countdown.countdown(date, function(evt) {
            $(this)
                .find(".launch-countdown__magnitude")
                .each(function() {
                    const format = $(this).attr("data-launch-unit");
                    $(this)
                        .find(".launch-countdown__value")
                        .html(evt.strftime(format));
                });
        });

        if ($(".glide").length < 1) {
            return;
        }

        const sliders = document.querySelectorAll(".glide");

        const glide_conf = {
            type: "carousel",
            perView: 1,
            focusAt: "center",
            gap: 50,
            breakpoints: {
                970: {
                    perView: 1
                }
            }
        };
        const gall_glide_conf = {
            type: "carousel",
            perView: 3,
            focusAt: "center",
            gap: 50,
            breakpoints: {
                600: {
                    perView: 1
                },
                1200: {
                    perView: 2
                }
            }
        };

        sliders.forEach(item => {
            var glide;
            const gallery_length = $(item).children(".gallery__pane").length;
            if (gallery_length == 1) {
                glide = new Glide(item, gall_glide_conf).mount();
            } else {
                glide = new Glide(item, glide_conf).mount();
            }
            glide.on(["mount", "mount.after", "run"], function() {
                setTimeout(() => {
                    const currentCarousel = item.querySelector(
                        ".glide__slides"
                    );

                    currentCarousel.style.transform =
                        "translate3d(" +
                        Math.round(
                            currentCarousel.style.transform.substring(
                                currentCarousel.style.transform.indexOf("(") +
                                    1,
                                currentCarousel.style.transform.indexOf("px")
                            )
                        ) +
                        "px, 0px, 0px) translateZ(0)";
                }, 500);
            });
            glide.mount();
        });
    });

    $(document).on("learndash_video_disable_assets", (video, isDisabled) => {
        const $markComplete = $(".ias-lesson-mark-complete");
        if (isDisabled) {
            $markComplete.addClass(DISABLED_CLASS);
        } else {
            $markComplete.removeClass(DISABLED_CLASS);
        }
    });

    function couponFormSlide() {
        if ($(window).width() <= 760) {
            var $coupon = $(".coupon-form");
            if ($coupon.length >= 1) {
                var $couponLabel = $coupon.find("label").first();
                $couponLabel.on("click", function(e) {
                    $coupon.toggleClass("active");
                });
            }
        }
    }
    couponFormSlide();
    $(window).on("resize", function() {
        couponFormSlide();
    });

    $(".ld-tab-content[id^=ld-tab-materials] a").each(function() {
        var $this = $(this);
        var href = $this.attr("href");
        if (href.indexOf(".pdf") !== -1) {
            $this.attr("target", "_blank");
        }
    });
})($);
